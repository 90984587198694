import axios from "axios";
import { journal_ngrok_url, payment_url } from "../bootapi";
import { supabase } from "..";
import {
  createtUserGoals,
  createUserHabit,
  createUserHabitLog,
  deleteUserGoal,
  deleteUserHabit,
  getUserGoals,
  getUserHabit,
  getUserHabitLogs,
  getUserJournalPassword,
  updateUserGoals,
  updateUserHabit,
  updateUserHabitLog,
} from "./paymentService";

export const getNewJournalSettings = (user_id, token) => {
  return axios.get(
    `${payment_url}/subsapp/journal_settings?user_id=${user_id}`,
    {
      headers: {
        Authorization: `${token}`,
      },
    }
  );
};
export const updateNewJournalSettings = (user_id, id, formdata, token) => {
  return axios.put(
    `${payment_url}/subsapp/journal_settings?id=${id}&user_id=${user_id}`,
    formdata,
    {
      headers: {
        Authorization: `${token}`,
      },
    }
  );
};
export const insertNewJournalSettings = (formdata, token) => {
  return axios.post(`${payment_url}/subsapp/journal_settings`, formdata, {
    headers: {
      Authorization: `${token}`,
    },
  });
};

export const addJournalEntry = (formdata, token) => {
  // console.log("60------->", formdata);
  return axios.post(
    `${journal_ngrok_url}/journal/insert_journal_data`,
    formdata,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const getGoodHabit = (user_id, token) => {
  return getUserHabit(user_id, token);
};
export const createGoodHabit = (formData, token) => {
  return createUserHabit(formData, token);
};
export const updateGoodHabit = (user_id, id, data, token) => {
  return updateUserHabit(user_id, id, data, token);
};
export const deleteGoodHabit = (id, user_id, token) => {
  return deleteUserHabit(id, user_id, token);
};
export const getGoodHabitLog = (user_id, token) => {
  return getUserHabitLogs(user_id, token);
};
export const createGoodHabitLog = (data, token) => {
  return createUserHabitLog(data, token);
};
export const updateGoodHabitLog = (id, user_id, data, token) => {
  return updateUserHabitLog(id, user_id, data, token);
};
export const deleteGoodHabitLog = (id, user_id, token) => {
  return deleteGoodHabitLog(id, user_id, token);
};

export const getJournalGoals = (user_id, token) => {
  return getUserGoals(user_id, token);
};
export const updateGoals = (id, user_id, data, token) => {
  return updateUserGoals(id, user_id, data, token);
};
export const createGoals = (formData, token) => {
  return createtUserGoals(formData, token);
};
export const deleteGoals = (id, user_id, token) => {
  return deleteUserGoal(id, user_id, token);
};

export const getJournalPassword = (user_id, token) => {
  return getUserJournalPassword(user_id, token);
};

export const getTodaySummary = (data, token, freemium = false) => {
  let url = `${journal_ngrok_url}/journal/today_journal_summary${
    freemium ? "_freemium" : ""
  }`;
  return axios.post(url, data, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getmoredaySummary = (data, token, freemium = false) => {
  let url = `${journal_ngrok_url}/journal/moredays_journal_summary${
    freemium ? "_freemium" : ""
  }`;
  return axios.post(url, data, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getJournalResources = (data, token) => {
  return axios.post(`${journal_ngrok_url}/journal/goals_resources`, data, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getJournalInsights = (data, token, freemium = false) => {
  let url = `${journal_ngrok_url}/journal/insights${
    freemium ? "_freemium" : ""
  }`;
  return axios.post(url, data, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};
// export const getJournalEntries = (user_id) => {
//   return supabase.from("seventhjournal").select().eq("user_id", user_id);
// };

// export const deleteJournalEntry = (id) => {
//   return supabase.from("seventhjournal").delete().eq("journal_id", id);
// };

export const getJournalEntries = (userId, token) => {
  const data = {
    select: "*",
    eq: { user_id: userId },
  };
  let url = `${journal_ngrok_url}/journal/get_seventhjournal`;
  return axios.post(url, data, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const deleteJournalEntry = (id, token) => {
  const data = {
    eq: {
      journal_id: id,
    },
  };
  let url = `${journal_ngrok_url}/journal/delete_seventhjournal`;
  return axios.post(url, data, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getJournalStatistics = (data, token, freemium = false) => {
  let url = `${journal_ngrok_url}/journal/statistics${
    freemium ? "_freemium" : ""
  }`;
  return axios.post(url, data, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const journalChatBot = (data, token) => {
  return axios.post(`${journal_ngrok_url}/journal/chatqa`, data, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const updateJournalData = (data, token) => {
  return axios.post(`${journal_ngrok_url}/journal/update_journal_data`, data, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const journalProgressTrack = (formData, token) => {
  return axios.post(
    `${journal_ngrok_url}/journal/progress_tracking`,
    formData,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const getJournalGallery = (formData, token) => {
  return axios.post(`${journal_ngrok_url}/journal/get_media_links`, formData, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deleteJournalGalleryImage = (data, token) => {
  return axios.post(`${journal_ngrok_url}/journal/delete_media_links`, data, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const showGratitudeBox = (formData, token) => {
  return axios.post(
    `${journal_ngrok_url}/journal/show_gratitude_box`,
    formData,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const generatePromptEntry = (formData, token) => {
  return axios.post(`${journal_ngrok_url}/journal/generate_prompt`, formData, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};
